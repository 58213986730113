<template>
  <v-app>
    <v-main>
      <v-container class="fill-height loginContainer" fluid>
        <v-row class="rowLogin">
          <v-col cols="5" class="colLogin">
            <v-card width="100%" height="100%" :loading="showLoading">
              <div class="loginDiv">
                <img
                  class="logoImage"
                  src="../../../assets/img/logoNath.png"
                  alt=""
                />
                <div class="loginText">Faça seu login!</div>
                <div class="text-caption">E-MAIL</div>
                <v-text-field
                  outlined
                  height="40"
                  autofocus
                  v-model="login"
                  @keyup.enter="authUser()"
                ></v-text-field>
                <v-col class="passwordLabel">
                  <div class="text-caption">SENHA</div>
                  <v-spacer></v-spacer>
                    <div @click="forgotPass" style="display:none" class="text-caption forgotPassDiv">
                      Esqueci minha senha
                    </div>
                </v-col>
                <v-text-field
                  outlined
                  height="40"
                  :type="!showPass ? 'password' : 'text'"
                  :append-icon="!showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPass = !showPass"
                  class="passwordTextField"
                  v-model="password"
                  @keyup.enter="authUser()"
                ></v-text-field>
                <v-btn
                  class="loginButton"
                  color="#177DFF"
                  depressed
                  dark
                  height="45"
                  width="100%"
                  @click="authUser()"
                  >Acessar conta</v-btn
                >
              </div>
            </v-card>
          </v-col>
          <v-col cols="7" class="colLogin loginImg"> </v-col>
          <!-- <v-col cols="12" sm="8" md="4">
            <v-card :loading="showLoading" border-light outlined>
              <v-card-title class="d-flex justify-center pa-8">
                <img src="@/assets/img/logo.png" width="200px" />
              </v-card-title>
              <v-card-text>
                <v-text-field label="Email"
                  @keyup.enter="authUser()" v-model="login" outlined></v-text-field>
                <v-text-field
                  label="Senha"
                  hide-details
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  v-model="password"
                  @keyup.enter="authUser()"
                  outlined
                  @click:append="showPass = !showPass"
                ></v-text-field>
              </v-card-text>
              <v-card-actions class="d-flex justify-center">
                <v-btn color="primary" style="width: 50%" large depressed @click="authUser()">Entrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-col> -->
        </v-row>

      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import AuthService from './../services/auth-service';



export default {
  data() {
    return {
      showLoading: false,
      showPass: false,
      login: '',
      password: '',
    };
  },
  methods: {
    forgotPass(){

    },
    async authUser() {
      this.showLoading = true;

      try {
        const { data } = await AuthService.login(this.login, this.password);
        window.localStorage.setItem('userId', data.id);
        window.localStorage.setItem('auth-token', data.token);

        // this.$router.push({ name: 'agenda' });
        window.location.href = '/atendimentos';
        // window.location.href = '/agenda';
        this.showLoading = false;
      } catch (err) {
        if (err.response && err.response.data) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }

        this.showLoading = false;
      }
    },
  },
};
</script>

<style>
.loginText {
  font-size: 30px;
  padding-bottom: 30px;
  font-family: 'Poppins', sans-serif !important;
}
.loginDiv {
  width: 60%;
  align-self: center;
  padding-bottom: 80px;
  max-height: 80%;
}

.loginDiv > .v-btn {
  margin: 0 !important;
}

.loginDiv .passwordLabel {
  display: flex;
  padding: 0px 0px;
}

.loginDiv .logoImage {
  width: 100px;
  height: auto;
  padding-bottom: 40px;
}

.loginDiv .v-input__slot {
  min-height: 0px !important;
  width: 100%;
}

.loginDiv .v-text-field--outlined fieldset {
  color: #e8e8e8 !important;
}

.loginDiv .text-caption {
  color: #b0bac9;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 2px !important;
}

.loginDiv .forgotPassDiv {
  letter-spacing: 0px !important;
}

.loginDiv .forgotPassDiv:hover {
  cursor: pointer
}

.loginDiv .passwordTextField .v-input__append-inner {
  margin: 0;
  align-self: center;
}

.loginDiv .loginButton {
  border-radius: 7px;
}

.loginContainer {
  padding: 0;
}
.colLogin {
  padding: 0;
  height: 100%;
}

.colLogin > .v-card {
  display: flex;
  place-content: center;
  border-radius: 0px;
}
.colLogin.loginImg {
  background-image: url('../../../assets/img/loginImg.jpg');
  background-size: cover;
}
.rowLogin {
  height: 100%;
}
</style>
